import { Button, Descriptions, Drawer, Typography } from 'antd'
import { CustomerForm } from '~/components/forms/CustomerForm'
import { CustomerProductForm } from '~/components/forms/CustomerProductForm'
import { AuthHoc } from '~/components/hocs/AuthHoc'
import { db } from '~/firebase-app'
import { collection, doc, getDoc } from 'firebase/firestore'
import { cCustomer, Customer } from '~/models/Customer'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useToggle } from 'react-use'
import { InvoicesTable } from '~/components/InvoicesTable'
import { CustomerProductsTabs } from '~/components/CustomerProductsTabs'
import env from '~/env'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const { Title } = Typography

export const CustomerShow = AuthHoc((props: any) => {
  const { customerId } = useParams<'customerId'>()
  const [customer, setCustomer] = useState<Customer>()

  const [isVisibleCustomerForm, toggleCustomerForm] = useToggle(false)

  const [isVisibleCustomerProductNewForm, toggleCustomerProductNewForm] =
    useToggle(false)

  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      const customerRef = doc(collection(db, cCustomer), customerId)
      const customerDoc = await getDoc(customerRef)

      if (!customerDoc.exists()) {
        navigate('/notfound')
        return
      }
      const customer = new Customer({
        id: customerDoc.id,
        ...customerDoc.data(),
      })

      setCustomer(customer)
    })()
  }, [customerId, navigate])

  if (!customerId || !customer) {
    return <div />
  }

  const callbackCustomer = (customer: Customer) => {
    setCustomer(customer)
  }

  const suzyURL = env('SUZY_URL')
  const suzyCustomerURL = `${suzyURL}/customers/${customer.suzyId}`

  return (
    <div className="container py-32 max-w-screen-sm">
      <div className="flex justify-center">
        <Title level={3} className="w-fit">
          <u>顧客詳細ページ</u>
        </Title>
      </div>
      <div className="py-8 flex">
        <Button type="primary" onClick={toggleCustomerForm} className="mr-10">
          顧客編集する
        </Button>
        <Button onClick={toggleCustomerProductNewForm} className="mr-10">
          商品登録する
        </Button>
      </div>
      <Descriptions title="顧客情報" bordered column={2}>
        <Descriptions.Item label="顧客名" span={2}>
          {customer.name}
          <Button type="text" target="_blank" href={suzyCustomerURL}>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </Button>
        </Descriptions.Item>
        <Descriptions.Item label="suzyId" span={2}>
          {customer.suzyId}
        </Descriptions.Item>
        <Descriptions.Item
          label="suzy請求書摘要欄"
          span={2}
          className="whitespace-pre-wrap"
        >
          {customer.suzyInvoiceMemoField}
        </Descriptions.Item>
      </Descriptions>
      <CustomerProductsTabs customerId={customerId} />

      <InvoicesTable customerId={customerId} />

      <Drawer
        title="顧客更新フォーム"
        width={720}
        onClose={toggleCustomerForm}
        visible={isVisibleCustomerForm}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <div className="py-16">
          <CustomerForm
            customerId={customerId}
            callbackCustomer={callbackCustomer}
            initialValues={{
              name: customer.name,
              suzyId: String(customer.suzyId),
              suzyInvoiceMemoField: customer.suzyInvoiceMemoField,
            }}
            toggleForm={toggleCustomerForm}
          />
        </div>
      </Drawer>
      <Drawer
        title="商品登録フォーム"
        width={720}
        onClose={toggleCustomerProductNewForm}
        visible={isVisibleCustomerProductNewForm}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <div className="py-16">
          <CustomerProductForm
            customerId={customerId}
            toggleCustomerProductForm={toggleCustomerProductNewForm}
          />
        </div>
      </Drawer>
    </div>
  )
})
