import { IAgency, Agency } from './Agency'
import { IProduct, Product } from './Product'
import { IProductOption, ProductOption } from './ProductOption'
import { Timestamp } from 'firebase/firestore'

export const cCustomerProduct = 'customerProducts'
export interface ICustomerProduct {
  id?: string
  tenantId: string
  product: IProduct
  productOptions: IProductOption[]
  agency: IAgency
  expectedUserCounts: number
  settlementMonth: number
  isAvailable: boolean
  contractedDate: Timestamp | null
  createdDate: Timestamp | null
  updatedDate: Timestamp | null
}

export class CustomerProduct implements ICustomerProduct {
  public readonly id: string
  public readonly tenantId: string
  public readonly product: Product
  public readonly productOptions: ProductOption[]
  public readonly agency: Agency
  public readonly expectedUserCounts: number
  public readonly settlementMonth: number
  public readonly isAvailable: boolean
  public readonly contractedDate: Timestamp | null
  public readonly createdDate: Timestamp | null
  public readonly updatedDate: Timestamp | null

  constructor({
    id = '',
    tenantId = '',
    product = {},
    productOptions = [] as IProductOption[],
    agency = {},
    expectedUserCounts = 0,
    settlementMonth = 0,
    isAvailable = true,
    contractedDate = null as Timestamp | null,
    createdDate = null as Timestamp | null,
    updatedDate = null as Timestamp | null,
  }) {
    this.id = id
    this.tenantId = tenantId
    this.product = new Product({ ...product })
    this.productOptions = productOptions.map(
      (productOption) => new ProductOption({ ...productOption }),
    )
    this.agency = new Agency({ ...agency })
    this.expectedUserCounts = expectedUserCounts
    this.settlementMonth = settlementMonth
    this.isAvailable = isAvailable
    this.contractedDate = contractedDate
    this.createdDate = createdDate
    this.updatedDate = updatedDate
  }

  public toObject(): ICustomerProduct {
    return {
      id: this.id,
      tenantId: this.tenantId,
      product: this.product.toObject(),
      productOptions: this.productOptions.map((productOption) =>
        productOption.toObject(),
      ),
      agency: this.agency.toObject(),
      expectedUserCounts: this.expectedUserCounts,
      settlementMonth: this.settlementMonth,
      isAvailable: this.isAvailable,
      contractedDate: this.contractedDate,
      createdDate: this.createdDate,
      updatedDate: this.updatedDate,
    }
  }
}
