import React, { FC } from 'react'
import { JibunEstimateNew } from '~/components/pages/JibunEstimateNew'
import { EstimateShow } from '~/components/pages/EstimateShow'
import Main from './components/pages/Main'
import NotFound from './components/pages/NotFound'
import { HeaderSiderContentLayout } from '~/components/layouts/HeaderSiderContentLayout'
import { useRoutes } from 'react-router-dom'
import { ContentLayout } from '~/components/layouts/ContentLayout'
import { CustomerIndex } from '~/components/pages/CustomerIndex'
import { CustomerShow } from '~/components/pages/CustomerShow'
import { AdminLogin } from '~/components/pages/AdminLogin'

type Props = {}

const routeObjs = [
  {
    element: <HeaderSiderContentLayout />,
    children: [
      {
        path: '/',
        element: <Main />,
      },
      { path: '/jibun/estimates/new', element: <JibunEstimateNew /> },
      {
        path: '/estimates/:estimateId',
        element: <EstimateShow />,
      },
      {
        path: '/admin/customers',
        element: <CustomerIndex />,
      },
      {
        path: '/admin/customers/:customerId',
        element: <CustomerShow />,
      },
      {
        path: '/admin/login',
        element: <AdminLogin />,
      },
      { path: '*', element: <NotFound /> },
    ],
  },
  {
    element: <ContentLayout />,
    children: [
      {
        path: '/share/estimates/:estimateId',
        element: <EstimateShow />,
      },
    ],
  },
]

const RoutesComponent: FC<Props> = (props) => {
  const element = useRoutes(routeObjs)
  return element
}

export default RoutesComponent
