import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Checkbox,
  Col,
  Row,
} from 'antd'
import useCollectionSubscription from '~/components/hooks/useCollectionSubscription'
import { db, Timestamp } from '~/firebase-app'
import {
  query,
  collection,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  where,
} from 'firebase/firestore'
import { cProduct, IProduct, Product } from '~/models/Product'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import generatePicker from 'antd/es/date-picker/generatePicker'
import { FC, useEffect, useState } from 'react'
import {
  cProductOption,
  IProductOption,
  ProductOption,
} from '~/models/ProductOption'
import { Agency, IAgency } from '~/models/Agency'
import { getMonth } from 'date-fns'
import { cCustomer } from '~/models/Customer'

import {
  cCustomerProduct,
  CustomerProduct,
  ICustomerProduct,
} from '~/models/CustomerProduct'
const { Option } = Select
const DatePicker = generatePicker(dateFnsGenerateConfig)

export type CustomerProductsFormType = {
  tenantId: string
  expectedUserCounts: string
  contractedDate?: Date
  productOptionIds?: string[]
  productId?: string
}

export type CustomerProductWithSelectedObjectFormType = {
  product: IProduct
  productOptions: IProductOption[]
  agency: IAgency
} & CustomerProductsFormType

export const customerProductsBlankForm: CustomerProductsFormType = {
  tenantId: '',
  expectedUserCounts: '',
  productOptionIds: [],
  productId: '',
}

type Props = {
  customerProduct?: CustomerProduct
  customerId: string
  initialValues?: CustomerProductsFormType
  toggleCustomerProductForm: () => void
}

export const CustomerProductForm: FC<Props> = (props) => {
  const {
    customerProduct,
    initialValues,
    customerId,
    toggleCustomerProductForm,
  } = props
  const [form] = Form.useForm()
  const [selectedProductId, setSelectedProductId] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const customerProductId = customerProduct?.id

  const isNew = !customerProductId

  const customerProductRef = collection(
    db,
    cCustomer,
    customerId,
    cCustomerProduct,
  )
  const customerProductDoc = customerProductId
    ? doc(
        collection(db, cCustomer, customerId, cCustomerProduct),
        customerProductId,
      )
    : null

  const { getFieldValue } = form

  const productOptionsQuery = query(
    collection(db, cProductOption),
    orderBy('name', 'asc'),
    where('productId', '==', selectedProductId),
  )
  const productOptions = useCollectionSubscription(
    productOptionsQuery,
    ProductOption,
    [selectedProductId],
  )

  useEffect(() => {
    form.resetFields()
  }, [form, initialValues])

  const onFinishAddCustomerProduct = async (
    values: CustomerProductWithSelectedObjectFormType,
  ) => {
    const {
      product,
      tenantId,
      productOptions,
      agency,
      expectedUserCounts,
      contractedDate,
    } = values

    const expectedUserCountsNumber = !isNaN(Number(expectedUserCounts))
      ? Number(expectedUserCounts)
      : 0

    const contractedDateDate = contractedDate || new Date()
    const settlementMonth = getMonth(contractedDateDate) + 1

    const customerProduct: ICustomerProduct = {
      product,
      expectedUserCounts: expectedUserCountsNumber,
      tenantId: tenantId.trim(),
      productOptions,
      agency,
      settlementMonth,
      isAvailable: true,
      contractedDate: Timestamp.fromDate(contractedDateDate),
      createdDate: Timestamp.now(),
      updatedDate: Timestamp.now(),
    }
    await addDoc(customerProductRef, customerProduct)
    toggleCustomerProductForm()
  }

  const onFinishUpdateCustomerProduct = async (
    values: CustomerProductWithSelectedObjectFormType,
  ) => {
    if (!customerProductDoc) {
      return
    }
    const { tenantId } = values

    const updateProduct = {
      tenantId: tenantId.trim(),
      updatedDate: Timestamp.now(),
    }

    await updateDoc(customerProductDoc, updateProduct)

    toggleCustomerProductForm()
  }

  const handleFinish = async (values: CustomerProductsFormType) => {
    setIsLoading(true)
    try {
      const selectedProductId = getFieldValue('productId')
      const selectedProduct =
        products.find((product) => product.id === selectedProductId) ||
        new Product({})
      const selectedProductOptionIds = getFieldValue('productOptionIds')
      const selectedProductOptions = productOptions.filter((productOption) =>
        selectedProductOptionIds.includes(productOption.id),
      )
      const selectedProductOptionsObj = selectedProductOptions.map(
        (productOption) => productOption.toObject(),
      )
      // 代理店対応
      const selectedAgency = new Agency({})

      if (isNew) {
        await onFinishAddCustomerProduct({
          ...values,
          product: selectedProduct.toObject(),
          productOptions: selectedProductOptionsObj,
          agency: selectedAgency.toObject(),
        })
      } else {
        await onFinishUpdateCustomerProduct({
          ...values,
          product: selectedProduct.toObject(),
          productOptions: selectedProductOptionsObj,
          agency: selectedAgency.toObject(),
        })
      }
      form.resetFields()
    } finally {
      setIsLoading(false)
    }
  }

  const productsQuery = query(collection(db, cProduct), orderBy('name', 'asc'))
  const products = useCollectionSubscription(productsQuery, Product)

  const onChangeProductId = (value: string) => {
    setSelectedProductId(value || '')
  }

  const matchProduct = products.find(
    (product) => product.id === selectedProductId,
  )

  return (
    <>
      <Form
        name="customerProducts"
        form={form}
        onFinish={handleFinish}
        autoComplete="off"
        layout="vertical"
        initialValues={initialValues || customerProductsBlankForm}
      >
        {isNew && (
          <Form.Item
            name={'productId'}
            label="商品ID"
            rules={[{ required: true, message: '必須項目です' }]}
          >
            <Select
              placeholder="サービス・商品を選択してください"
              onChange={onChangeProductId}
              allowClear
            >
              {products.map((product) => {
                const isMonthly = product.type === 'monthly'

                return (
                  <Option
                    value={product.id}
                    key={product.id}
                    disabled={!isMonthly}
                  >
                    {product.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        )}
        {isNew && matchProduct?.type === 'yearly' && (
          <>
            <Form.Item
              name={'expectedUserCounts'}
              label="想定利用数"
              rules={[{ required: true, message: '必須項目です' }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name={'contractedDate'}
              label="利用開始日"
              rules={[{ required: true, message: '必須項目です' }]}
            >
              <DatePicker picker="month" />
            </Form.Item>
          </>
        )}
        {isNew && (
          <>
            <Form.Item name="productOptionIds">
              <Checkbox.Group className="w-full">
                <Row>
                  {productOptions.map((productOption) => {
                    return (
                      <Col span={12} key={productOption.id}>
                        <Checkbox value={productOption.id}>
                          {productOption.name}
                        </Checkbox>
                      </Col>
                    )
                  })}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </>
        )}

        <Form.Item
          name={'tenantId'}
          label="テナントID"
          rules={[{ required: true, message: '必須項目です' }]}
        >
          <Input />
        </Form.Item>

        <div className="flex flex-row-reverse py-10">
          <Form.Item>
            <Button type="primary" loading={isLoading} htmlType="submit">
              {isNew ? '商品登録' : '商品更新'}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  )
}
