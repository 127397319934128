import { Form, Input, Button, InputNumber, notification } from 'antd'
import { db, Timestamp, functions } from '../../firebase-app'
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { cCustomer, ICustomer, Customer } from '~/models/Customer'
import { FC, useState } from 'react'
import { httpsCallable } from 'firebase/functions'
import { SuzyCustomer } from '~/models/Suzy'

export type CustomerFormType = {
  name: string
  suzyId: string
  suzyInvoiceMemoField: string
}

export const customerBlankForm: CustomerFormType = {
  name: '',
  suzyId: '',
  suzyInvoiceMemoField: '',
}

type Props = {
  customerId?: string
  initialValues?: CustomerFormType
  toggleForm: () => void
  callbackCustomer: (customer: Customer) => void
}

export const CustomerForm: FC<Props> = (props) => {
  const { initialValues, customerId, toggleForm, callbackCustomer } = props
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [form] = Form.useForm()

  const isNew = !customerId

  const addCustomer = async (values: CustomerFormType) => {
    const { suzyId } = values

    const numberSuzyId = Number(suzyId)

    const customerQuery = query(
      collection(db, cCustomer),
      where('suzyId', '==', numberSuzyId),
    )
    const { empty } = await getDocs(customerQuery)
    if (!empty) {
      notification.error({
        message: '既にそのsuzyIDの顧客は作られています',
      })
      return
    }

    const getSuzyCustomer = httpsCallable<
      { suzyId: number },
      { suzyCustomer: SuzyCustomer }
    >(functions, 'getSuzyCustomer')

    let suzyCustomer: SuzyCustomer
    try {
      const { data } = await getSuzyCustomer({
        suzyId: numberSuzyId,
      })

      suzyCustomer = data.suzyCustomer
    } catch (error) {
      if (error instanceof Error) {
        notification.error({
          message: error.message,
        })
      }
      return
    }
    const name = suzyCustomer.name

    const customerRef = await addDoc(collection(db, cCustomer), {
      name,
      suzyId: numberSuzyId,
      createdDate: Timestamp.now(),
      updatedDate: Timestamp.now(),
    } as ICustomer)

    const customerDoc = await getDoc(customerRef)
    const customer = new Customer({ id: customerDoc.id, ...customerDoc.data() })
    callbackCustomer(customer)
    toggleForm()
  }

  const updateCustomer = async (values: CustomerFormType) => {
    const { name, suzyInvoiceMemoField } = values
    const customerRef = doc(collection(db, cCustomer), customerId)
    await updateDoc(customerRef, {
      name,
      suzyInvoiceMemoField,
      updatedDate: Timestamp.now(),
    })

    const customerDoc = await getDoc(customerRef)

    const customer = new Customer({
      id: customerDoc.id,
      ...customerDoc.data(),
    })
    callbackCustomer(customer)
    toggleForm()
  }

  const handleFinish = async (values: CustomerFormType) => {
    setIsLoading(true)
    try {
      if (isNew) {
        await addCustomer(values)
      } else {
        await updateCustomer(values)
      }
      form.resetFields()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Form
        name="customer"
        form={form}
        onFinish={handleFinish}
        autoComplete="off"
        layout="vertical"
        initialValues={initialValues || customerBlankForm}
      >
        {!isNew && (
          <>
            <Form.Item
              label="顧客名"
              name={'name'}
              rules={[{ required: true, message: '必須項目です' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="suzy請求書摘要欄" name="suzyInvoiceMemoField">
              <Input.TextArea />
            </Form.Item>
          </>
        )}

        {isNew && (
          <Form.Item
            label="SUZYID"
            name={'suzyId'}
            rules={[{ required: true, message: '必須項目です' }]}
          >
            <InputNumber />
          </Form.Item>
        )}

        <div className="flex flex-row-reverse py-10">
          <Form.Item>
            <Button type="primary" loading={isLoading} htmlType="submit">
              {isNew ? '顧客作成' : '顧客更新'}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  )
}
