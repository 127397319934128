import { Form, Input, Button, notification } from 'antd'
import { db, Timestamp, functions } from '../../firebase-app'
import { addDoc, collection, getDoc } from 'firebase/firestore'
import { cCustomer, ICustomer, Customer } from '~/models/Customer'
import { FC, useState } from 'react'
import { httpsCallable } from 'firebase/functions'
import { SuzyCustomer } from '~/models/Suzy'

export type SuzyCustomerFormType = {
  name: string
  address: string
  staffName: string
  emailTo: string
  emailCc: string
  transferSource: string
  comment: string
  memo: string
}

export const suzyCustomerBlankForm: SuzyCustomerFormType = {
  name: '',
  address: '',
  staffName: '',
  emailTo: '',
  emailCc: '',
  transferSource: '',
  comment: '',
  memo: '',
}

type Props = {
  toggleForm: () => void
  callbackCustomer: (customer: Customer) => void
}

export const SuzyCustomerForm: FC<Props> = (props) => {
  const { toggleForm, callbackCustomer } = props
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [form] = Form.useForm()

  const addSuzyCustomer = async (values: SuzyCustomerFormType) => {
    const {
      name,
      address,
      emailTo,
      emailCc,
      staffName,
      comment,
      memo,
      transferSource,
    } = values

    const postSuzyCustomer = httpsCallable<SuzyCustomer, SuzyCustomer>(
      functions,
      'postSuzyCustomer',
    )

    let suzyCustomer: SuzyCustomer
    try {
      const { data } = await postSuzyCustomer({
        name,
        address,
        email_to: emailTo,
        email_cc: emailCc,
        staff_name: staffName,
        comment,
        transfer_source: transferSource,
      })

      suzyCustomer = data
    } catch (error) {
      if (error instanceof Error) {
        notification.error({
          message: error.message,
        })
      }
      return
    }
    const customerRef = await addDoc(collection(db, cCustomer), {
      name: suzyCustomer.name,
      suzyId: Number(suzyCustomer.id),
      suzyInvoiceMemoField: memo,
      createdDate: Timestamp.now(),
      updatedDate: Timestamp.now(),
    } as ICustomer)
    const customerDoc = await getDoc(customerRef)
    const customer = new Customer({ id: customerDoc.id, ...customerDoc.data() })
    callbackCustomer(customer)
    toggleForm()
  }

  const handleFinish = async (values: SuzyCustomerFormType) => {
    setIsLoading(true)
    try {
      addSuzyCustomer(values)
      form.resetFields()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Form
        name="suzyCustomer"
        form={form}
        onFinish={handleFinish}
        autoComplete="off"
        layout="vertical"
        initialValues={suzyCustomerBlankForm}
      >
        <Form.Item
          label="顧客名"
          name={'name'}
          rules={[{ required: true, message: '必須項目です' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="請求先住所" name={'address'} rules={[]}>
          <Input />
        </Form.Item>

        <Form.Item label="請求先担当者名" name={'staffName'} rules={[]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="請求送信先（to:）"
          name={'emailTo'}
          rules={[
            { required: true, message: '必須項目です' },
            {
              type: 'email',
              message: '不正なemail形式です。',
            },
          ]}
          normalize={(v) => v.trim()}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="請求送信先（cc:）"
          name={'emailCc'}
          rules={[
            {
              type: 'email',
              message: '不正なemail形式です。',
            },
          ]}
          normalize={(v) => v.trim()}
        >
          <Input />
        </Form.Item>

        <Form.Item label="口座名義" name={'transferSource'} rules={[]}>
          <Input />
        </Form.Item>

        <Form.Item label="内部メモ" name={'comment'} rules={[]}>
          <Input />
        </Form.Item>

        <Form.Item label="摘要欄" name="memo">
          <Input.TextArea />
        </Form.Item>

        <div className="flex flex-row-reverse py-10">
          <Form.Item>
            <Button type="primary" loading={isLoading} htmlType="submit">
              SUZYに顧客を作成
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  )
}
