import { faPen, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Descriptions, Button, Popconfirm } from 'antd'
import { format } from 'date-fns'
import { db, Timestamp } from '~/firebase-app'
import { collection, doc, updateDoc } from 'firebase/firestore'
import { cCustomer } from '~/models/Customer'
import { cCustomerProduct, CustomerProduct } from '~/models/CustomerProduct'
import { FC } from 'react'
import { UsageHistoriesTable } from './UsageHistoriesTable'
type Props = {
  customerProduct: CustomerProduct
  toggleCustomerProductUpdateForm: (nextValue?: any) => void
  customerId: string
}

export const CustomerProductView: FC<Props> = (props) => {
  const { customerProduct, toggleCustomerProductUpdateForm, customerId } = props

  const { product, agency, productOptions } = customerProduct

  const isAgency = !!agency.code

  const productName = product.name
  const isYearly = product.type === 'yearly'

  const handleCustomerProductDelete = async () => {
    const customerProductRef = doc(
      collection(db, cCustomer, customerId, cCustomerProduct),
      customerProduct.id,
    )
    await updateDoc(customerProductRef, {
      isAvailable: false,
      updatedDate: Timestamp.now(),
    })
  }

  return (
    <>
      <Descriptions bordered column={2}>
        <Descriptions.Item label="商品名" span={2}>
          {productName}
          <Popconfirm
            title={`${productName}を削除します`}
            okText="はい"
            cancelText="いいえ"
            onConfirm={handleCustomerProductDelete}
          >
            <Button type="text">
              <FontAwesomeIcon icon={faTrashCan} />
            </Button>
          </Popconfirm>
        </Descriptions.Item>
        {isYearly && (
          <>
            <Descriptions.Item label="想定利用数" span={2}>
              {customerProduct.expectedUserCounts}
            </Descriptions.Item>
            <Descriptions.Item label="利用開始日" span={2}>
              {customerProduct.contractedDate &&
                format(customerProduct.contractedDate.toDate(), 'yyyy/MM/dd')}
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item label="商品オプション" span={2}>
          {productOptions.map((productOption) => {
            return (
              <span key={productOption.id} className="pr-2">
                {productOption.name}
              </span>
            )
          })}
        </Descriptions.Item>
        <Descriptions.Item label="テナントID" span={2}>
          {customerProduct.tenantId}
          <Button onClick={toggleCustomerProductUpdateForm} type="text">
            <FontAwesomeIcon icon={faPen} />
          </Button>
        </Descriptions.Item>
        {isAgency && (
          <Descriptions.Item label="代理店" span={2}>
            {agency.to}
          </Descriptions.Item>
        )}
      </Descriptions>

      <UsageHistoriesTable
        serviceType={customerProduct.product.serviceType}
        customerProductId={customerProduct.id}
      />
    </>
  )
}
