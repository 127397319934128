export const cDiscount = 'discounts'

export type DiscountType = 'volumeDiscount' | 'additionalLicense'

export interface IDiscount {
  id?: string // firestore id
  productId: string
  bottom: number
  up: number
  charge: number
  descriptions: string[]
  type: DiscountType
}

export class Discount implements IDiscount {
  public readonly id: string
  public readonly productId: string
  public readonly bottom: number
  public readonly up: number
  public readonly charge: number
  public readonly descriptions: string[]
  public readonly type: DiscountType
  constructor({
    id = '',
    productId = '',
    bottom = 0,
    up = 0,
    charge = 0,
    descriptions = [],
    type = 'volumeDiscount' as DiscountType,
  }) {
    this.id = id
    this.productId = productId
    this.bottom = bottom
    this.up = up
    this.charge = charge
    this.descriptions = descriptions
    this.type = type
  }

  public toObject(): IDiscount {
    return {
      id: this.id,
      productId: this.productId,
      bottom: this.bottom,
      up: this.up,
      charge: this.charge,
      descriptions: this.descriptions,
      type: this.type,
    }
  }
}
