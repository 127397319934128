import {
  CollectionReference,
  DocumentData,
  onSnapshot,
  Query,
} from 'firebase/firestore'
import { useState, useEffect } from 'react'

interface DocumentModel {
  id: string
}

export default function useCollectionSubscription<T extends DocumentModel>(
  ref:
    | CollectionReference<DocumentData>
    | Query<DocumentData>
    | null
    | undefined,
  ctor: { new (params: any): T },
  dependencies: any[] = [],
  { initialItems = [] } = {},
) {
  const [items, setItems] = useState<T[]>(initialItems)
  useEffect(() => {
    if (!ref) return
    const unsubscribe = onSnapshot(ref, ({ docs }) => {
      setItems(docs.map((_) => new ctor({ id: _.id, ref: _.ref, ..._.data() })))
    })
    return unsubscribe
  }, dependencies)
  return items
}
