import { Button, notification } from 'antd'
import { FC, useEffect, useState } from 'react'
import { db } from '../../firebase-app'
import { doc, collection, getDoc } from 'firebase/firestore'
import { cEstimate, Estimate } from '~/models/Estimate'
import { useNavigate, useParams } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { EstimateView } from '~/components/EstimateView'

type Props = {}

export const EstimateShow: FC<Props> = (props) => {
  const { estimateId } = useParams<'estimateId'>()
  const [estimate, setEstimate] = useState<Estimate>()
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      const estimatesRef = doc(collection(db, cEstimate), estimateId)
      const estimateDoc = await getDoc(estimatesRef)
      if (!estimateDoc.exists()) {
        navigate('/not_found')

        return
      }
      const estimate = new Estimate({
        id: estimateDoc.id,
        ...estimateDoc.data(),
      })

      setEstimate(estimate)
    })()
  }, [estimateId, navigate])

  if (!estimate) {
    return <div></div>
  }

  const handlePrintClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    window.print()
  }

  const handleCopyToClipboard = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    if (!estimate) {
      return
    }
    const origin = window.location.origin
    const shareURL = `${origin}/share/estimates/${estimate.id}`
    navigator.clipboard.writeText(shareURL).then(
      () => {
        notification.open({
          message: `リンクをクリップボードにコピーしました。`,
        })
      },
      (err) => {
        notification.open({
          message: `コピーできませんでした。`,
          description: `${err.message}`,
        })
      },
    )
  }

  return (
    <section className="page">
      <div className="container  max-w-screen-sm">
        <div className="py-16 no-print" />

        <EstimateView {...estimate} />
        <div className="no-print">
          <div className="flex flex-row-reverse py-10 ">
            <div className="pl-5">
              <Button
                htmlType="button"
                type="primary"
                onClick={handlePrintClick}
              >
                印刷
              </Button>
            </div>
            <div className="">
              <Button
                htmlType="button"
                type="primary"
                onClick={handleCopyToClipboard}
              >
                <FontAwesomeIcon icon={faCopy} />
                リンクをコピー
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
