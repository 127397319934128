import { format } from 'date-fns'
export interface IRowDetail {
  productId: string
  productName: string
  descriptions: string[]
  unitPrice: number
  quantity: number
  amount: number
}

export class RowDetail implements IRowDetail {
  public readonly productId: string
  public readonly productName: string
  public readonly descriptions: string[]
  public readonly unitPrice: number
  public readonly quantity: number
  public readonly amount: number
  constructor({
    productId = '',
    productName = '',
    descriptions = [] as string[],
    unitPrice = 0,
    quantity = 0,
    amount = 0,
  }) {
    this.productId = productId
    this.productName = productName
    this.descriptions = descriptions
    this.unitPrice = unitPrice
    this.quantity = quantity
    this.amount = amount
  }

  public toObject(): IRowDetail {
    return {
      productId: this.productId,
      productName: this.productName,
      descriptions: this.descriptions,
      unitPrice: this.unitPrice,
      quantity: this.quantity,
      amount: this.amount,
    }
  }

  public getInvoiceDescriptions(invoiceDate: Date): string[] {
    const invoiceDateString = format(invoiceDate, 'yyyy年MM月')

    const invoiceDescriptions = this.descriptions.map((description, index) => {
      if (index === 0) {
        return description + `(${invoiceDateString}分)`
      }

      return description
    })

    return invoiceDescriptions
  }
}
