/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import { Button, Layout } from 'antd'
import { auth } from '~/firebase-app'
import { FirebaseContext } from './contexts/FirebaseContext'
import { useContext } from 'react'

export default function Header(props: any) {
  const { firebaseUser } = useContext(FirebaseContext)

  const isLoggedIn = !!firebaseUser

  // TODO: ログアウト関連の見た目
  const handleLogout: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    auth.signOut()
  }

  return (
    <Layout.Header
      style={{ zIndex: 1, width: '100%', height: 80 }}
      className="header no-print"
    >
      <div className="container h-7 flex items-center justify-between">
        <div className="flex justify-center">
          <Link to={{ pathname: '/' }}>
            <p className="text-2xl font-bold text-green-400">
              見積もり作成システム
            </p>
          </Link>
        </div>

        {isLoggedIn && (
          <div className="flex items-center">
            <p className="text-green-400">{firebaseUser.email}</p>
            <Button htmlType="button" onClick={handleLogout}>
              logout
            </Button>
          </div>
        )}
      </div>
    </Layout.Header>
  )
}
