import {
  Form,
  Input,
  Button,
  Select,
  Typography,
  InputNumber,
  Row,
  Space,
  Checkbox,
  Col,
  Divider,
  Spin,
} from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { db, Timestamp, functions } from '../../firebase-app'
import {
  collection,
  addDoc,
  query,
  where,
  doc,
  setDoc,
  increment,
  updateDoc,
  orderBy,
} from 'firebase/firestore'
import { useNavigate } from 'react-router'
import { Agency, cAgency } from '~/models/Agency'
import { useSearchParams } from 'react-router-dom'
import { cEstimate, IEstimate } from '~/models/Estimate'
import { countsStringOfServices, cProduct, Product } from '~/models/Product'
import { addMonths, format } from 'date-fns'
import {
  blankCalculatedValues,
  CalculatedValues,
  CulculateEstimate,
} from '~/components/CalculateEstimate'
import useCollectionSubscription from '~/components/hooks/useCollectionSubscription'
import useDocumentSubscription from '~/components/hooks/useDocumentSubscription'
import { cSerialNumber, SerialNumber } from '~/models/SerialNumber'
import { httpsCallable } from 'firebase/functions'
import { PhoneNumberFormatUtil } from '~/helpers/PhoneNumberFormtUtil'
import { cProductOption, ProductOption } from '~/models/ProductOption'
import { cDiscount, Discount } from '~/models/Discount'

const { Option } = Select
const { Title } = Typography

type FormType = {
  userCounts: string
  monthCounts: string
  productId: string
  customer: {
    companyName: string
    department: string
    chargeName: string
    email: string
    phoneNumber: string
  }
  to: string
}

const blankForm: FormType = {
  userCounts: '',
  monthCounts: '',
  productId: '',
  customer: {
    companyName: '',
    department: '',
    chargeName: '',
    email: '',
    phoneNumber: '',
  },
  to: '',
}

type Props = {}

export const JibunEstimateNew: FC<Props> = (props) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [agency, setAgency] = useState<Agency>(new Agency({}))
  const [calculatedValues, setCalculatedValues] = useState<CalculatedValues>(
    blankCalculatedValues,
  )
  const [userCounts, setUserCounts] = useState<number | undefined>()
  const [monthCounts, setMonthCounts] = useState<number | undefined>()
  const [productId, setProductId] = useState<string>('')
  const [productOptionIds, setProductOptionIds] = useState<string[]>([])
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isAgency = !!agency.code
  const agencyCode = searchParams.get('agencyCode')
  const subtotalAmount = calculatedValues.details.reduce(
    (preValue, currentValue) => preValue + currentValue.amount,
    0,
  )
  const isValidCalculateValues = subtotalAmount > 0

  const agencyQuery = agencyCode
    ? query(collection(db, cAgency), where('code', '==', agencyCode))
    : null
  const agencies = useCollectionSubscription(agencyQuery, Agency)

  useEffect(() => {
    if (agencies.length === 0) {
      form.setFieldsValue({
        customer: {
          companyName: '',
        },
        to: '',
      })

      return
    }

    const agency = agencies[0]

    form.setFieldsValue({
      customer: {
        companyName: agency.to,
      },
      to: agency.to,
    })

    setAgency(agency)
  }, [agencies, form])

  const productsQuery = query(
    collection(db, cProduct),
    where('isEstimateAvailable', '==', true),
    orderBy('name', 'asc'),
  )
  const products = useCollectionSubscription(productsQuery, Product)
  const serialNumberRef = doc(collection(db, cSerialNumber), 'totalCounts')
  const serialNumber = useDocumentSubscription(serialNumberRef, SerialNumber)
  const productOptionsQuery = query(
    collection(db, cProductOption),
    orderBy('name', 'asc'),
  )
  const productOptions = useCollectionSubscription(
    productOptionsQuery,
    ProductOption,
  )
  const discountsQuery = productId
    ? query(
        collection(db, cDiscount),
        where('productId', '==', productId),
        orderBy('bottom', 'asc'),
      )
    : null

  const discounts = useCollectionSubscription(discountsQuery, Discount, [
    productId,
  ])

  const matchProductOptions = productOptions.filter((productOptions) => {
    return productOptions.productId === productId
  })

  const matchProduct = products.find((product) => product.id === productId)

  const onFinish = async (values: FormType) => {
    if (!matchProduct) {
      return
    }
    setIsLoading(true)
    const estimateCounts = serialNumber?.estimateCounts || 0

    const notifyWorldEstimateCreated = httpsCallable(
      functions,
      'notifyWorldEstimateCreated',
    )
    const { customer, to } = values

    const expirationDate = addMonths(new Date(), 3)

    const today = new Date()

    const estimateIndex = `${format(today, 'yyyy_MM_dd')}_${estimateCounts + 1}`
    const details = calculatedValues.details.map((detail) => detail.toObject())

    const estimateData: IEstimate = {
      estimateIndex,
      type: matchProduct.type,
      name: 'じぶんシリーズご利用料金',
      customer,
      to,
      monthCounts: monthCounts || 0,
      details,
      createdDate: Timestamp.fromDate(today),
      expirationDate: Timestamp.fromDate(expirationDate),
    }

    const estimateDoc = await addDoc(
      collection(db, cEstimate),
      estimateData,
    ).then(async (estimateDoc) => {
      if (!serialNumber) {
        await setDoc(serialNumberRef, {
          estimateCounts: increment(1),
        })
      } else {
        await updateDoc(serialNumberRef, {
          estimateCounts: increment(1),
        })
      }

      return estimateDoc
    })
    const id = estimateDoc.id

    const origin = window.location.origin
    const estimatePathname = `/estimates/${id}`
    const estimateUrl = origin + estimatePathname

    await notifyWorldEstimateCreated({
      estimateUrl,
      createdDate: format(today, 'yyyy-MM-dd'),
      customer,
    })
    setIsLoading(false)
    navigate(`/estimates/${id}`)
  }
  const handleChangeUserCounts = (value: number | null) => {
    if (!value) return
    setUserCounts(value)
  }
  const handleChangeProductId = (value: string) => {
    setProductId(value)
  }
  const handleChangeMonthCounts = (value: number | null) => {
    if (!value) return
    setMonthCounts(value)
  }

  const callbackCalculatedValues = useCallback((props: CalculatedValues) => {
    setCalculatedValues(props)
  }, [])

  const isMonthlyPlan = matchProduct?.type === 'monthly'

  const selectedProductOptions = useMemo(() => {
    return productOptions.filter(
      (productOption) =>
        productOptionIds.includes(productOption.id) &&
        productOption.productId === productId,
    )
  }, [productId, productOptionIds, productOptions])

  return (
    <div className="container py-32 max-w-screen-sm">
      <Spin spinning={isLoading}>
        <div className="flex justify-center">
          <Title level={3} className="w-fit">
            <u>見積りフォーム</u>
          </Title>
        </div>
        <Form
          name="estimate"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          initialValues={blankForm}
        >
          <Divider orientation="left">
            入力者情報（見積書には表示されません）
          </Divider>
          <Form.Item
            label="会社名"
            name={['customer', 'companyName']}
            rules={[{ required: true, message: '必須項目です' }]}
          >
            <Input disabled={isAgency} />
          </Form.Item>
          <Input.Group>
            <Row>
              <Space>
                <Form.Item label="部署名" name={['customer', 'department']}>
                  <Input />
                </Form.Item>

                <Form.Item
                  label="担当者名"
                  name={['customer', 'chargeName']}
                  rules={[{ required: true, message: '必須項目です' }]}
                >
                  <Input />
                </Form.Item>
              </Space>
            </Row>
          </Input.Group>
          <Input.Group>
            <Row>
              <Space>
                <Form.Item
                  label="メールアドレス"
                  name={['customer', 'email']}
                  rules={[
                    { required: true, message: '必須項目です' },
                    {
                      type: 'email',
                      message: '不正なemail形式です。',
                    },
                  ]}
                >
                  <Input placeholder="example@example.com" />
                </Form.Item>

                <Form.Item
                  label="電話番号"
                  name={['customer', 'phoneNumber']}
                  normalize={(value) => {
                    if (PhoneNumberFormatUtil.isValidNumber(value)) {
                      return PhoneNumberFormatUtil.formatTelNumber(value)
                    }

                    return value
                  }}
                  rules={[
                    { required: true, message: '必須項目です' },
                    {
                      validator: async (_, value) => {
                        if (!PhoneNumberFormatUtil.isValidNumber(value)) {
                          throw new Error('電話番号を確認してください')
                        }
                      },
                    },
                  ]}
                >
                  <Input placeholder="xxx-xxxx-xxxx" />
                </Form.Item>
              </Space>
            </Row>
          </Input.Group>
          <Divider orientation="left">見積書情報</Divider>
          <Form.Item
            label="サービス・商品"
            name="productId"
            rules={[{ required: true, message: '必須項目です' }]}
          >
            <Select
              onChange={handleChangeProductId}
              placeholder="サービス・商品を選択してください"
              allowClear
            >
              {products.map((product) => (
                <Option value={product.id} key={product.id}>
                  {product.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="productOptions"
            getValueFromEvent={(props) => {
              setProductOptionIds(props)

              return props
            }}
          >
            <Checkbox.Group className="w-full">
              <Row>
                {matchProductOptions.map((productOption) => {
                  if (isAgency && !productOption.isAgencyAvailable) {
                    return <div key={productOption.id}></div>
                  }
                  return (
                    <Col span={12} key={productOption.id}>
                      <Checkbox value={productOption.id}>
                        {productOption.name}
                      </Checkbox>
                    </Col>
                  )
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Input.Group>
            <Row>
              <Space>
                <Form.Item
                  label={`想定${
                    countsStringOfServices[
                      matchProduct?.serviceType || 'jibunPage'
                    ]
                  }`}
                  name="userCounts"
                  rules={[
                    { required: true, message: '必須項目です' },
                    {
                      message: '半角数字で入力してください',
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                >
                  <InputNumber
                    className="w-full"
                    onChange={handleChangeUserCounts}
                    min={1}
                  />
                </Form.Item>

                {isMonthlyPlan && (
                  <Form.Item
                    label="利用月数"
                    name="monthCounts"
                    rules={[{ required: true, message: '必須項目です' }]}
                  >
                    <InputNumber
                      className="w-full"
                      onChange={handleChangeMonthCounts}
                      min={1}
                    />
                  </Form.Item>
                )}
              </Space>
            </Row>
          </Input.Group>

          <Form.Item
            label="宛名（御中）"
            name="to"
            rules={[{ required: true, message: '必須項目です' }]}
          >
            <Input placeholder="株式会社テスト" />
          </Form.Item>

          {matchProduct?.productDescriptions.map(
            (productDescription, index) => (
              <p key={productDescription}>{productDescription}</p>
            ),
          )}
          <CulculateEstimate
            productOptions={selectedProductOptions}
            monthCounts={monthCounts}
            userCounts={userCounts}
            product={matchProduct}
            discounts={discounts}
            agency={agency}
            calculatedValues={calculatedValues}
            callbackCalculatedValues={callbackCalculatedValues}
          />
          <div className="flex flex-row-reverse py-10">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isValidCalculateValues}
              >
                御見積作成
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </div>
  )
}
