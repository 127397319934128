import React, { useEffect, useState, FC } from 'react'
import { FirebaseContext } from '../contexts/FirebaseContext'
import { auth } from '../../firebase-app'
import { Auth, User } from 'firebase/auth'

export const FirebaseProvider: FC = ({ children }) => {
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null)
  // 初回表示時にfirebaseUserの取得前か後かを区別するのに使う
  const [isInitializing, setInitializing] = useState<boolean>(true)

  useEffect(() => {
    const unsubscribe = (auth as Auth).onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        setFirebaseUser(firebaseUser)
        setInitializing(false)
      } else {
        setFirebaseUser(firebaseUser)
        setInitializing(false)
      }
    })

    return unsubscribe
  }, [])

  return (
    <FirebaseContext.Provider value={{ firebaseUser, isInitializing }}>
      {children}
    </FirebaseContext.Provider>
  )
}
