import { format } from 'date-fns'
import { Timestamp } from 'firebase/firestore'

export const cUsageHistory = 'usageHistories'
export interface IUsageHistory {
  id?: string
  customerProductId: string
  year: number
  month: number
  counts: number
  overageCharges: number
  createdDate: Timestamp | null
  updatedDate: Timestamp | null
}

export class UsageHistory implements IUsageHistory {
  public readonly id: string
  public readonly customerProductId: string
  public readonly year: number
  public readonly month: number
  public readonly counts: number
  public readonly overageCharges: number
  public readonly createdDate: Timestamp | null
  public readonly updatedDate: Timestamp | null
  constructor({
    id = '',
    customerProductId = '',
    year = 0,
    month = 0,
    counts = 0,
    overageCharges = 0,
    createdDate = null,
    updatedDate = null,
  }) {
    this.id = id
    this.customerProductId = customerProductId
    this.year = year
    this.month = month
    this.counts = counts
    this.overageCharges = overageCharges
    this.createdDate = createdDate
    this.updatedDate = updatedDate
  }

  public toObject(): IUsageHistory {
    return {
      id: this.id,
      customerProductId: this.customerProductId,
      year: this.year,
      month: this.month,
      counts: this.counts,
      overageCharges: this.overageCharges,
      createdDate: this.createdDate,
      updatedDate: this.updatedDate,
    }
  }

  public getYearMonthString(): string {
    return format(new Date(this.year, this.month - 1), 'yyyy-MM')
  }
}
