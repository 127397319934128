import { Table, Typography } from 'antd'
import { db } from '~/firebase-app'
import { query, collection, where, orderBy } from 'firebase/firestore'
import { cInvoice, Invoice } from '~/models/Invoice'
import { FC } from 'react'
import useCollectionSubscription from './hooks/useCollectionSubscription'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import env from '~/env'
const { Title } = Typography
type Props = {
  customerId: string
}

export const InvoicesTable: FC<Props> = (props) => {
  const { customerId } = props
  const invoicesQuery = query(
    collection(db, cInvoice),
    orderBy('createdDate', 'desc'),
    where('customerId', '==', customerId),
  )
  const invoices = useCollectionSubscription(invoicesQuery, Invoice, [
    customerId,
  ])

  const columns = [
    {
      title: '日付',
      width: 150,
      dataIndex: 'yearMonth',
      key: 'yearMonth',
      ellipsis: true,
    },
    {
      title: '請求金額',
      width: 150,
      dataIndex: 'amount',
      key: 'amount',
      ellipsis: true,
    },
    {
      title: '請求書Link',
      width: 150,
      dataIndex: 'suzyInvoiceLink',
      key: 'suzyInvoiceLink',
      ellipsis: true,
      render: (suzyInvoiceURL: string) => {
        return (
          <a target="_blank" href={suzyInvoiceURL} rel="noreferrer">
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </a>
        )
      },
    },
  ]

  const data = invoices.map((invoice, index) => {
    const suzyURL = env('SUZY_URL')
    const suzyInvoiceURL = `${suzyURL}/invoices/${invoice.suzyInvoice.id}`

    return {
      key: index,
      yearMonth: invoice.getYearMonthString(),
      amount: invoice.getPriceIncludingTax(),
      suzyInvoiceLink: suzyInvoiceURL,
    }
  })

  return (
    <>
      <Title level={5} className="pt-5">
        請求情報
      </Title>
      <Table columns={columns} dataSource={data} pagination={false} />
    </>
  )
}
