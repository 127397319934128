import { FirebaseContext } from '~/components/contexts/FirebaseContext'
import { FC, useContext } from 'react'
import { Navigate, useLocation } from 'react-router'

export const AuthHoc = (WrappedComponent: FC<any>) => {
  return function AuthPage(props: any): JSX.Element {
    const { firebaseUser, isInitializing } = useContext(FirebaseContext)
    const location = useLocation()

    const isLoggedIn = !!firebaseUser

    // firebaseUserがとれる前に遷移させないため
    if (isInitializing) {
      return <div></div>
    }

    return (
      <>
        {isLoggedIn ? (
          <WrappedComponent {...props} />
        ) : (
          <Navigate to="/" state={{ from: location }} replace />
        )}
      </>
    )
  }
}
