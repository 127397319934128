import { format } from 'date-fns'
import { Timestamp } from 'firebase/firestore'

export const cInvoice = 'invoices'

export type InvoiceDetailAttribute = {
  title: string // 項目名
  quantity: number // 数量
  price: number // 単価
  tax_rate: string // 消費税率
  tax_free?: boolean // 非課税
  item_id?: number // 商品ID ???
  approve: string // 確認済みかどうか('0': 未確認, '1': 確認済み)
}

export type InvoiceUserAttribute = {
  user_id: number // 担当者ID required
}

export type SuzyInvoice = {
  id?: number // 請求書 id
  title: string // 件名 required
  customer_id: number // 顧客ID sg-accounting だと suzy id required
  staff_name?: string // 請求担当者名
  address?: string // 請求先住所
  email_to: string // 請求送信先(to:) required
  email_cc?: string // 請求送信先(cc:)
  memo?: string // 概要
  comment?: string // 内部メモ
  exclude_deposit?: boolean // 入金引当対象外にする
  invoice_date: string // 請求日 required
  send_on?: string // 送信予定日
  payment_limit_date: string // 支払い期限日 required
  paid_price?: number // 入金額
  paid_date?: string // 入金日
  paid_memo?: string // 入金メモ
  partial_paid?: boolean // 一部入金
  transfar_fee?: boolean // 振込手数料はお客様負担
  file_url?: string // 添付ファイルURL
  year_payment?: boolean // 年払い
  allocating_date?: string // 計上日
  invoice_details_attributes: InvoiceDetailAttribute[]
  invoice_users_attributes: InvoiceUserAttribute[]
}

export const blankSuzyInvoice: SuzyInvoice = {
  title: '',
  customer_id: 0,
  email_to: '',
  invoice_date: '',
  payment_limit_date: '',
  invoice_details_attributes: [],
  invoice_users_attributes: [],
}

export interface IInvoice {
  id?: string // firestore docid
  customerId: string
  suzyInvoice: SuzyInvoice
  year: number
  month: number
  createdDate: Timestamp | null
}

export class Invoice implements IInvoice {
  public readonly id: string
  public readonly customerId: string
  public readonly suzyInvoice: SuzyInvoice
  public readonly year: number
  public readonly month: number
  public readonly createdDate: Timestamp | null

  constructor({
    id = '',
    customerId = '',
    suzyInvoice = blankSuzyInvoice,
    year = 0,
    month = 0,
    createdDate = null,
  }) {
    this.id = id
    this.customerId = customerId
    this.suzyInvoice = suzyInvoice
    this.year = year
    this.month = month
    this.createdDate = createdDate
  }

  public toObject(): IInvoice {
    return {
      id: this.id,
      customerId: this.customerId,
      suzyInvoice: this.suzyInvoice,
      year: this.year,
      month: this.month,
      createdDate: this.createdDate,
    }
  }
  public getYearMonthString(): string {
    return format(new Date(this.year, this.month - 1), 'yyyy-MM')
  }

  public getPriceIncludingTax(): number {
    const details = this.suzyInvoice.invoice_details_attributes
    const amount = details.reduce((previousValue, currentValue) => {
      if (currentValue.tax_free) {
        return previousValue + currentValue.price * currentValue.quantity
      } else {
        return (
          previousValue +
          currentValue.price *
            currentValue.quantity *
            Number(currentValue.tax_rate) +
          currentValue.price * currentValue.quantity
        )
      }
    }, 0)
    return amount
  }
}
