import { Table } from 'antd'
import { db } from '~/firebase-app'
import { query, collection, where, orderBy } from 'firebase/firestore'
import { countsStringOfServices, ProductServiceType } from '~/models/Product'
import { cUsageHistory, UsageHistory } from '~/models/UsageHistory'
import { FC } from 'react'
import useCollectionSubscription from './hooks/useCollectionSubscription'

type Props = {
  customerProductId: string
  serviceType: ProductServiceType
}

export const UsageHistoriesTable: FC<Props> = (props) => {
  const { customerProductId, serviceType } = props
  const usageHistoriesQuery = query(
    collection(db, cUsageHistory),
    orderBy('createdDate', 'desc'),
    where('customerProductId', '==', customerProductId),
  )
  const usageHistories = useCollectionSubscription(
    usageHistoriesQuery,
    UsageHistory,
    [customerProductId],
  )

  const columns = [
    {
      title: '日付',
      width: 150,
      dataIndex: 'yearMonth',
      key: 'yearMonth',
      ellipsis: true,
    },
    {
      title: countsStringOfServices[serviceType],
      width: 150,
      dataIndex: 'counts',
      key: 'counts',
      ellipsis: true,
    },
  ]

  const data = usageHistories.map((usageHistory, index) => {
    return {
      key: index,
      yearMonth: usageHistory.getYearMonthString(),
      counts: usageHistory.counts,
    }
  })

  return (
    <>
      <Table columns={columns} dataSource={data} pagination={false} />
    </>
  )
}
