import { FC } from 'react'
import { auth } from '~/firebase-app'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import btn from '../../images/btn_google_signin_dark_normal_web.png'
import { Location, useLocation, useNavigate } from 'react-router'
import { Typography } from 'antd'

const { Title } = Typography

type LocationState = {
  from?: Location
} | null

type Props = {}

export const AdminLogin: FC<Props> = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state as LocationState

  const handleLogin: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
      .then((result) => {
        const from = locationState?.from?.pathname || '/admin/customers'
        navigate(from, { replace: true })
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  return (
    <div className="container py-32 h-full">
      <div className="flex justify-center">
        <Title level={3} className="w-fit">
          <u>管理者ログイン</u>
        </Title>
      </div>
      <div className="flex justify-center h-full">
        <button type="button" onClick={handleLogin}>
          <img src={btn} alt="" />
        </button>
      </div>
    </div>
  )
}
