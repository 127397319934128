import { Table, Typography } from 'antd'
import { RowDetail } from '~/models/RowDetail'
import { FC } from 'react'
import currencyFormatter from 'currency-formatter'
import { ProductType } from '~/models/Product'

const { Text } = Typography

const TAX_RATE = 0.1

const yealryColumns = [
  {
    title: '番号',
    dataIndex: 'indexNumber',
  },
  {
    title: '商品＆詳細',
    dataIndex: 'productDetails',
    render: (productDetail: {
      productName: string
      descriptions: string[]
    }) => (
      <>
        <p>{productDetail.productName}</p>
        {productDetail.descriptions.map((description, index) => {
          return <p key={description}>・{description}</p>
        })}
      </>
    ),
  },
  {
    title: '数量',
    dataIndex: 'quantity',
    render: (text: any) => <p className="text-right">{text}</p>,
  },
  {
    title: '単価',
    dataIndex: 'unitPrice',
    render: (text: any) => <p className="text-right">{text}</p>,
  },
  {
    title: '金額',
    dataIndex: 'amount',
    render: (text: any) => <p className="text-right">{text}</p>,
  },
]

const monthlyColumns = [
  {
    title: '番号',
    dataIndex: 'indexNumber',
  },
  {
    title: '商品＆詳細',
    dataIndex: 'productDetails',
    render: (productDetail: {
      productName: string
      descriptions: string[]
    }) => (
      <>
        <p>{productDetail.productName}</p>
        {productDetail.descriptions.map((description, index) => {
          return <p key={description}>・{description}</p>
        })}
      </>
    ),
  },
  {
    title: '数量',
    dataIndex: 'quantity',
    render: (text: any) => <p className="text-right">{text}</p>,
  },
  {
    title: '単価',
    dataIndex: 'unitPrice',
    render: (text: any) => <p className="text-right">{text}</p>,
  },
  {
    title: '金額',
    dataIndex: 'amount',
    render: (text: any) => <p className="text-right">{text}</p>,
  },
]

type Props = {
  details: RowDetail[]
  monthCounts: number
  type: ProductType | undefined
}

export const EstimateDetailsTable: FC<Props> = (props) => {
  const { details, monthCounts, type } = props
  const isMonthly = type === 'monthly'
  const columns = isMonthly ? monthlyColumns : yealryColumns
  const data = details.map((detail, index) => {
    const data = {
      key: index + 1,
      indexNumber: index + 1,
      productDetails: {
        productName: detail.productName,
        descriptions: detail.descriptions,
      },
      quantity: detail.quantity,
      unitPrice: currencyFormatter.format(detail.unitPrice, { code: 'JPY' }),
      amount: currencyFormatter.format(detail.amount, { code: 'JPY' }),
    }

    return data
  })

  const subtotalAmount = isMonthly
    ? details.reduce(
        (preValue, currentValue) => preValue + currentValue.amount,
        0,
      ) * monthCounts
    : details.reduce(
        (preValue, currentValue) => preValue + currentValue.amount,
        0,
      )
  const tax = subtotalAmount * TAX_RATE
  const totalAmount = subtotalAmount + tax

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered
      size="small"
      summary={(pageData) => {
        return (
          <>
            {isMonthly && (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    className="text-center"
                    colSpan={2}
                    index={1}
                  >
                    月数
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right" index={4}>
                    <Text>{monthCounts}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )}
            <Table.Summary.Row>
              <Table.Summary.Cell className="text-center" colSpan={2} index={1}>
                計
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell className="text-right" index={4}>
                <Text>
                  {currencyFormatter.format(subtotalAmount, { code: 'JPY' })}
                </Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell className="text-center" colSpan={2} index={1}>
                消　　費　　税　　等
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell className="text-right" index={4}>
                <Text>{currencyFormatter.format(tax, { code: 'JPY' })}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell className="text-center" colSpan={2} index={1}>
                合　　　　　　　　計
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell className="text-right" index={4}>
                <Text>
                  {currencyFormatter.format(totalAmount, { code: 'JPY' })}
                </Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
      }}
    />
  )
}
