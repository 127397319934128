import {
  PhoneNumberUtil,
  PhoneNumber,
  PhoneNumberFormat,
} from 'google-libphonenumber'

// 日本の国コード
const region = 'JP'

const util = PhoneNumberUtil.getInstance()

export class PhoneNumberFormatUtil {
  public static isValidNumber(value: string) {
    let number: PhoneNumber
    try {
      // 番号と地域を設定
      number = util.parseAndKeepRawInput(value, region)
    } catch (e) {
      return false
    }
    // 電話番号の有効性チェックを返却
    return util.isValidNumberForRegion(number, region)
  }

  public static formatTelNumber(value: string) {
    let number: PhoneNumber
    try {
      // 番号と地域を設定
      number = util.parseAndKeepRawInput(value, region)
    } catch (e) {
      return null
    }
    // 電話番号の有効性チェック
    if (!util.isValidNumberForRegion(number, region)) {
      return null
    }

    // ハイフン付きの形式で返却
    return util.format(number, PhoneNumberFormat.NATIONAL)
  }
}
