import { Timestamp } from 'firebase/firestore'

export const cCustomer = 'customers'

export interface ICustomer {
  id?: string
  name: string
  suzyId: number
  suzyInvoiceMemoField: string
  createdDate: Timestamp | null
  updatedDate: Timestamp | null
}

export class Customer implements ICustomer {
  public readonly id: string
  public readonly name: string
  public readonly suzyId: number
  public readonly suzyInvoiceMemoField: string
  public readonly createdDate: Timestamp | null
  public readonly updatedDate: Timestamp | null
  constructor({
    id = '',
    name = '',
    suzyId = 0,
    suzyInvoiceMemoField = '',
    createdDate = null,
    updatedDate = null,
  }) {
    this.id = id
    this.name = name
    this.suzyId = suzyId
    this.suzyInvoiceMemoField = suzyInvoiceMemoField
    this.createdDate = createdDate
    this.updatedDate = updatedDate
  }

  public toObject(): ICustomer {
    return {
      id: this.id,
      name: this.name,
      suzyId: this.suzyId,
      suzyInvoiceMemoField: this.suzyInvoiceMemoField,
      createdDate: this.createdDate,
      updatedDate: this.updatedDate,
    }
  }
}
