import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { useContext } from 'react'
import { FirebaseContext } from './contexts/FirebaseContext'

export default function Sider(props: any) {
  const { firebaseUser, isInitializing } = useContext(FirebaseContext)
  const isLoggedIn = !!firebaseUser
  return (
    <Layout.Sider width={250} className="no-print">
      <Menu mode="inline" style={{ height: '100%', borderRight: 0 }}>
        <Menu.Item key={1}>
          <Link to={'/jibun/estimates/new'}>
            <p className="font-bold">じぶんシリーズ見積もり作成</p>
          </Link>
        </Menu.Item>

        {!isInitializing && isLoggedIn && (
          <Menu.Item key={2}>
            <Link to={'/admin/customers'}>
              <p className="font-bold">顧客一覧</p>
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </Layout.Sider>
  )
}
