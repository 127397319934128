import { FirebaseContext } from '~/components/contexts/FirebaseContext'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// import { getFirebaseHttpsFunctionUrl } from '~/firebase-app'

export default function Main(_props: any) {
  const { firebaseUser, isInitializing } = useContext(FirebaseContext)
  const isLoggedIn = !!firebaseUser

  // DEBUG: testThrowError 確認用
  // const [, throwAsyncError] = useState()
  // useEffect(() => {
  //   fetch(getFirebaseHttpsFunctionUrl('testThrowError')).catch((e) =>
  //     throwAsyncError(() => {
  //       throw e
  //     }),
  //   )
  // }, [])

  return (
    <>
      <div className="flex justify-center">
        <Link to={'/jibun/estimates/new'}>
          <p className="text-lg py-5">じぶんシリーズ見積もり作成</p>
        </Link>
      </div>
      {!isInitializing && isLoggedIn && (
        <div className="flex justify-center">
          <Link to={'/admin/customers'}>
            <p className="text-lg py-5">顧客一覧</p>
          </Link>
        </div>
      )}
    </>
  )
}
