export const cSerialNumber = 'serialNumber'
export interface ISerialNumber {
  id?: string
  estimateCounts: number
}

export class SerialNumber implements ISerialNumber {
  public readonly id: string
  public readonly estimateCounts: number

  constructor({ id = '', estimateCounts = 0 }) {
    this.id = id
    this.estimateCounts = estimateCounts
  }

  public toObject(): ISerialNumber {
    return {
      id: this.id,
      estimateCounts: this.estimateCounts,
    }
  }
}
