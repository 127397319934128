import { User } from 'firebase/auth'
import { createContext } from 'react'

type FirebaseContextValue = {
  firebaseUser: User | null
  isInitializing: boolean
}

export const FirebaseContext = createContext<FirebaseContextValue>({
  firebaseUser: null,
  isInitializing: true,
})
