import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React, { PropsWithChildren, useEffect } from 'react'
import invariant from 'tiny-invariant'

const releaseStage = import.meta.env.MODE
const shouldNotifyBugsnag = ['production', 'staging'].includes(releaseStage)

const ignoreWords = ['ResizeObserver loop limit exceeded']

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  releaseStage,
  plugins: [new BugsnagPluginReact()],
  onError: async (event) => {
    if (ignoreWords.includes(event.errors[0]?.errorMessage)) return false
    return shouldNotifyBugsnag
  },
})

const BugsnagErrorBoundary =
  Bugsnag.getPlugin('react')?.createErrorBoundary(React)

type ErrorViewProps = {
  error: Error
  info: React.ErrorInfo
  clearError: () => void
}

function ErrorView(_props: ErrorViewProps) {
  return (
    <div className="container flex flex-col items-center space-y-4 my-20">
      <h1 className="font-bold text-lg">エラーが発生しました</h1>
      <div>
        <a href="/">トップ画面へ戻る</a>
      </div>
    </div>
  )
}

export function ErrorBoundary(props: PropsWithChildren<{}>) {
  invariant(BugsnagErrorBoundary)

  return (
    <BugsnagErrorBoundary FallbackComponent={ErrorView}>
      {props.children}
    </BugsnagErrorBoundary>
  )
}
