import { Layout } from 'antd'

import { Outlet } from 'react-router-dom'
import { FC } from 'react'

type Props = {}

export const ContentLayout: FC<Props> = (props) => {
  return (
    <Layout>
      <Layout.Content>
        <Outlet />
      </Layout.Content>
    </Layout>
  )
}
