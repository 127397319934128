import { Layout } from 'antd'
import Header from '../Header'
import Sider from '../Sider'
import { Outlet } from 'react-router-dom'
import { FC } from 'react'

type Props = {}

export const HeaderSiderContentLayout: FC<Props> = (props) => {
  return (
    <Layout className="min-h-screen">
      <Header />
      <Layout>
        <Sider />
        <Layout>
          <Layout.Content>
            <Outlet />
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  )
}
