import { Agency } from '../models/Agency'

import { ProductOption } from '../models/ProductOption'
import { format } from 'currency-formatter'

export class RowDetailCalculator {
  public constructor(
    readonly charge: number,
    readonly quantity: number,
    readonly agency: Agency,
    readonly productOptions: ProductOption[],
  ) {}

  public isAgency() {
    return !!this.agency.code
  }
  public isTeamPlanOption() {
    const teamPlanOption = this.productOptions.find(
      (productOption) => productOption.type === 'teamPlan',
    )

    return !!teamPlanOption
  }

  public standardPrice() {
    return this.charge
  }

  // 単価
  public unitPrice() {
    if (this.isAgency()) {
      return this.standardPrice() * this.agency.rate
    }
    const teamPlanOption = this.productOptions.find(
      (productOption) => productOption.type === 'teamPlan',
    )
    if (!!teamPlanOption) {
      return this.standardPrice() * teamPlanOption.value
    }

    return this.standardPrice()
  }

  // 金額
  public amount() {
    return this.unitPrice() * this.quantity
  }

  public getPartitionDescription() {
    if (this.isAgency()) {
      return [
        `仕切価格：${format(this.unitPrice(), {
          code: 'JPY',
        })}（標準価格：${format(this.standardPrice(), {
          code: 'JPY',
        })}）`,
      ]
    }

    if (this.isTeamPlanOption()) {
      const teamPlanOption = this.productOptions.find(
        (productOption) => productOption.type === 'teamPlan',
      )
      return [`チームプラン割引適用：${(1 - teamPlanOption!.value) * 100}%OFF`]
    }
    return []
  }
}
