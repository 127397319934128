import { Agency } from '~/models/Agency'
import { Discount } from '~/models/Discount'
import { countsStringOfServices, Product } from '~/models/Product'
import { FC, useEffect } from 'react'
import { EstimateDetailsTable } from './EstimateDetailsTable'
import { notification } from 'antd'
import { ProductOption } from '~/models/ProductOption'
import { JibunCalculator } from '~/util/JibunCalculator'
import { FirebaseFunctionsError } from '~/models/FirebaseFunctionError'
import { RowDetail } from '~/models/RowDetail'

export type CalculatedValues = {
  details: RowDetail[]
}

export const blankCalculatedValues = {
  details: [],
}

type Props = {
  userCounts: number | undefined
  monthCounts: number | undefined
  product: Product | undefined
  productOptions: ProductOption[]
  discounts: Discount[]
  agency: Agency
  calculatedValues: CalculatedValues
  callbackCalculatedValues: (props: CalculatedValues) => void
}

export const CulculateEstimate: FC<Props> = ({
  userCounts = 0,
  monthCounts = 0,
  product,
  productOptions,
  discounts,
  agency,
  calculatedValues,
  callbackCalculatedValues,
}) => {
  // 計算
  useEffect(() => {
    if (!product) {
      callbackCalculatedValues(blankCalculatedValues)
      return
    }
    if (discounts.length === 0) {
      callbackCalculatedValues(blankCalculatedValues)
      return
    }
    if (product.type === 'monthly' && monthCounts === 0) {
      callbackCalculatedValues(blankCalculatedValues)
      return
    }

    const jibunCalculator = new JibunCalculator(
      discounts,
      agency,
      product,
      productOptions,
    )

    let rowDetails: RowDetail[]
    let isOverUseCounts: boolean
    try {
      ;({ rowDetails, isOverUseCounts } = jibunCalculator.getRowDetailsInfo(
        userCounts,
        [],
      ))
    } catch (error) {
      if (error instanceof FirebaseFunctionsError) {
        notification.open({
          message: error.message,
        })
      }

      callbackCalculatedValues(blankCalculatedValues)
      return
    }

    if (isOverUseCounts) {
      notification.open({
        message: `想定${
          countsStringOfServices[product.serviceType]
        }が想定範囲外です。お問い合わせください`,
      })

      callbackCalculatedValues(blankCalculatedValues)
      return
    }

    const calculatedValues = {
      monthCounts,
      details: rowDetails,
    }

    callbackCalculatedValues(calculatedValues)
  }, [
    agency,
    callbackCalculatedValues,
    discounts,
    product,
    userCounts,
    monthCounts,
    productOptions,
  ])

  return (
    <EstimateDetailsTable
      monthCounts={monthCounts}
      details={calculatedValues.details}
      type={product?.type}
    />
  )
}
