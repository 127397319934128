import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faF, faP, faR } from '@fortawesome/free-solid-svg-icons'
export const cProduct = 'products'

export type ProductType = 'monthly' | 'yearly'
export type ProductServiceType = 'jibunPage' | 'jibunForm' | 'jibunRecord'

export const countsStringOfServices: {
  [serviceType in ProductServiceType]: string
} = {
  jibunPage: '利用ユーザー数',
  jibunForm: 'フォーム投稿数',
  jibunRecord: '作成URL数',
}

const iconOfServices: {
  [serviceType in ProductServiceType]: IconDefinition
} = {
  jibunPage: faP,
  jibunForm: faF,
  jibunRecord: faR,
}

export interface IProduct {
  id?: string
  name: string
  descriptions: string[]
  productDescriptions: string[]
  isEstimateAvailable: boolean
  type: ProductType
  serviceType: ProductServiceType
}

export class Product implements IProduct {
  public readonly id: string
  public readonly name: string
  public readonly descriptions: string[]
  public readonly productDescriptions: string[]
  public readonly isEstimateAvailable: boolean
  public readonly type: ProductType
  public readonly serviceType: ProductServiceType
  constructor({
    id = '',
    name = '',
    descriptions = [] as string[],
    productDescriptions = [] as string[],
    isEstimateAvailable = false,
    type = 'monthly' as ProductType,
    serviceType = 'jibunPage' as ProductServiceType,
  }) {
    this.id = id
    this.name = name
    this.descriptions = descriptions
    this.productDescriptions = productDescriptions
    this.isEstimateAvailable = isEstimateAvailable
    this.type = type
    this.serviceType = serviceType
  }

  public toObject(): IProduct {
    return {
      id: this.id,
      name: this.name,
      descriptions: this.descriptions,
      productDescriptions: this.productDescriptions,
      isEstimateAvailable: this.isEstimateAvailable,
      type: this.type,
      serviceType: this.serviceType,
    }
  }

  public getServiceTypeIcon(): IconDefinition {
    return iconOfServices[this.serviceType]
  }
}
