import { Timestamp } from 'firebase/firestore'
import { ProductType } from './Product'
import { IRowDetail, RowDetail } from './RowDetail'

export const cEstimate = 'estimates'

export type EstimateCustomer = {
  companyName: string
  department: string
  chargeName: string
  email: string
  phoneNumber: string
}

export interface IEstimate {
  id?: string
  estimateIndex: string
  type: ProductType
  name: string
  monthCounts: number
  customer: EstimateCustomer
  to: string
  details: IRowDetail[]
  createdDate: Timestamp | null
  expirationDate: Timestamp | null
}

export class Estimate implements IEstimate {
  public readonly id: string
  public readonly estimateIndex: string
  public readonly type: ProductType
  public readonly name: string
  public readonly monthCounts: number
  public readonly customer: EstimateCustomer
  public readonly to: string
  public readonly details: RowDetail[]
  public readonly createdDate: Timestamp | null
  public readonly expirationDate: Timestamp | null
  constructor({
    id = '',
    estimateIndex = '',
    type = 'monthly' as const,
    name = '',
    monthCounts = 0,
    customer = blankEstimateCustomer,
    to = '',
    details = [] as IRowDetail[],
    createdDate = null,
    expirationDate = null,
  }) {
    this.id = id
    this.estimateIndex = estimateIndex
    this.type = type
    this.name = name
    this.monthCounts = monthCounts
    this.customer = customer
    this.to = to
    this.details = details.map((detail) => new RowDetail({ ...detail }))
    this.createdDate = createdDate
    this.expirationDate = expirationDate
  }

  public toObject(): IEstimate {
    return {
      id: this.id,
      estimateIndex: this.estimateIndex,
      type: this.type,
      name: this.name,
      monthCounts: this.monthCounts,
      customer: this.customer,
      to: this.to,
      details: this.details.map((detail) => detail.toObject()),
      createdDate: this.createdDate,
      expirationDate: this.expirationDate,
    }
  }
}

export const blankEstimateCustomer: EstimateCustomer = {
  companyName: '',
  department: '',
  chargeName: '',
  email: '',
  phoneNumber: '',
}
