import { Link } from 'react-router-dom'

export default function NotFound(props: any) {
  return (
    <div className="container">
      <div className="text-center text-gray-500">
        <p className="text-2xl font-bold my-5">ページが見つかりません</p>
        <p className="text-sm my-5">
          指定されたファイルがこのWebサイトで見つかりませんでした。
          URLに誤りがないか確認して、再試行してください。
        </p>
        <Link to={'/'}>
          <p className="text-blue-500">TOP画面に戻る</p>
        </Link>
      </div>
    </div>
  )
}
