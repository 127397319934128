// https://future-architect.github.io/typescript-guide/exception.html

class BaseError extends Error {
  constructor(e?: string) {
    super(e)
    this.name = new.target.name
    // TODO: react側は ES5 functions側は es2017 がターゲットにされている。
    // 下記の行はTypeScriptの出力ターゲットがES2015より古い場合(ES3, ES5)のみ必要
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

// オリジナルエラー
// code にfunctions.https.HttpsErrorと同等のものをもたせる
// client側に firebase-functions がないのでcodeを別途定義している。
export class FirebaseFunctionsError extends BaseError {
  constructor(
    public code:
      | 'ok'
      | 'cancelled'
      | 'unknown'
      | 'invalid-argument'
      | 'deadline-exceeded'
      | 'not-found'
      | 'already-exists'
      | 'permission-denied'
      | 'resource-exhausted'
      | 'failed-precondition'
      | 'aborted'
      | 'out-of-range'
      | 'unimplemented'
      | 'internal'
      | 'unavailable'
      | 'data-loss'
      | 'unauthenticated',
    e?: string,
  ) {
    super(e)
  }
}

export class FatalError extends FirebaseFunctionsError {
  constructor(
    public code: 'invalid-argument' | 'permission-denied' | 'internal',

    e?: string,
  ) {
    super(code, e)
  }
}
