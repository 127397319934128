export const cAgency = 'agencies'

export interface IAgency {
  id?: string
  code: string
  rate: number
  to: string
}

export class Agency implements IAgency {
  public readonly id: string
  public readonly code: string
  public readonly rate: number
  public readonly to: string
  constructor({ id = '', code = '', rate = 0, to = '' }) {
    this.id = id
    this.code = code
    this.rate = rate
    this.to = to
  }

  public toObject(): IAgency {
    return {
      id: this.id,
      code: this.code,
      rate: this.rate,
      to: this.to,
    }
  }
}
