import { RowDetailCalculator } from './RowDetailCulculator'
import { Agency } from '../models/Agency'
import { Discount } from '../models/Discount'
import { FirebaseFunctionsError } from '../models/FirebaseFunctionError'
import { RowDetail } from '../models/RowDetail'
import { Product } from '../models/Product'
import { format } from 'currency-formatter'
import { ProductOption } from '../models/ProductOption'

export interface IJibunCalculator {
  // 見積書、請求書の{ rowDetails: RowDetail[]; isOverUseCounts: boolean }を出力
  getRowDetailsInfo: (
    useCounts: number,
    diffCharges: number[],
  ) => { rowDetails: RowDetail[]; isOverUseCounts: boolean }
  //   年額時の月々の差額
  getPageDiffCharge: (expectedUseCounts: number, useCounts: number) => number // 消費税なしの差分を返す。
}

export class JibunCalculator implements IJibunCalculator {
  constructor(
    private readonly discounts: Discount[], // productで絞り込まれたdiscounts
    private readonly agency: Agency,
    private readonly product: Product,
    private readonly productOptions: ProductOption[],
  ) {}

  private getDiscountIndex(useCounts: number) {
    const matchDiscounts = this.discounts.filter(
      (discount) => discount.bottom <= useCounts,
    )

    const matchDiscountIndex = matchDiscounts.length - 1

    return matchDiscountIndex
  }

  // 追加ライセンス等の場合分け
  private makeDiscountWithQuantity(useCounts: number) {
    const matchDiscountIndex = this.getDiscountIndex(useCounts)
    const matchDiscount = this.discounts[matchDiscountIndex]
    if (matchDiscount.type === 'additionalLicense') {
      const previousDiscount = this.discounts[matchDiscountIndex - 1]
      const previousDiscountUp = previousDiscount.up
      const previousQuantity = 1
      const aditionalUserQuantity = useCounts - previousDiscountUp
      return [
        {
          discount: previousDiscount,
          quantity: previousQuantity,
        },
        {
          discount: matchDiscount,
          quantity: aditionalUserQuantity,
        },
      ]
    }
    if (matchDiscount.type === 'volumeDiscount') {
      const quantity = 1
      return [
        {
          discount: matchDiscount,
          quantity,
        },
      ]
    }
    throw new FirebaseFunctionsError(
      'internal',
      'ディスカウントタイプが想定外です。',
    )
  }

  private replaceDiscountDescription(
    sourceText: string,
    discount: Discount,
    quantity: number,
    useCounts: number,
  ) {
    let text = sourceText
    text = text.replace(
      /{BOTTOM}/g,
      String(discount.bottom.toLocaleString('ja-JP')),
    )
    text = text.replace(/{UP}/g, String(discount.up.toLocaleString('ja-JP')))
    text = text.replace(
      /{CHARGE}/g,
      String(format(discount.charge, { code: 'JPY' })),
    )
    text = text.replace(/{QANTITY}/g, String(quantity.toLocaleString('ja-JP')))
    text = text.replace(
      /{USECOUNTS}/g,
      String(useCounts.toLocaleString('ja-JP')),
    )

    return text
  }

  private makeDetailByDiscount(
    discount: Discount,
    quantity: number,
    useCounts: number,
  ) {
    const productId = this.product.id!
    const rowCalculator = new RowDetailCalculator(
      discount.charge,
      quantity,
      this.agency,
      this.productOptions,
    )
    const partitionValueDescriptions: string[] =
      rowCalculator.getPartitionDescription()
    const replacedDiscountDescriptions = discount.descriptions.map(
      (description) =>
        this.replaceDiscountDescription(
          description,
          discount,
          quantity,
          useCounts,
        ),
    )
    const detailDescriptions = [
      ...replacedDiscountDescriptions,
      ...partitionValueDescriptions,
      ...this.product.descriptions,
    ]

    const detail: RowDetail = new RowDetail({
      productId,
      productName: this.product.name,
      descriptions: detailDescriptions,
      unitPrice: rowCalculator.unitPrice(),
      quantity,
      amount: rowCalculator.amount(),
    })

    return detail
  }

  private makeDetailByProductOption(productOption: ProductOption) {
    const quantity = 1
    const detailCalculator = new RowDetailCalculator(
      productOption.value,
      quantity,
      this.agency,
      this.productOptions,
    )
    const partitionValueDescriptions: string[] =
      detailCalculator.getPartitionDescription()

    const detailDescriptions = [
      ...productOption.descriptions,
      ...partitionValueDescriptions,
      ...this.product.descriptions,
    ]

    const kintoneIpAddressRestrictionsDetail: RowDetail = new RowDetail({
      productId: this.product.id,
      productName: this.product.name,
      descriptions: detailDescriptions,
      unitPrice: detailCalculator.unitPrice(),
      quantity,
      amount: detailCalculator.amount(),
    })
    return kintoneIpAddressRestrictionsDetail
  }

  private makeDetailByDiffCharge(diffCharge: number) {
    const quantity = 1
    const detailCalculator = new RowDetailCalculator(
      diffCharge,
      quantity,
      this.agency,
      this.productOptions,
    )
    const diffChargeDetail: RowDetail = new RowDetail({
      productId: this.product.id,
      productName: this.product.name,
      descriptions: ['去年の差額'],
      unitPrice: detailCalculator.unitPrice(),
      quantity,
      amount: detailCalculator.amount(),
    })

    return diffChargeDetail
  }

  public getRowDetailsInfo(useCounts: number, diffCharges: number[]) {
    const discountWithQuantityArray = this.makeDiscountWithQuantity(useCounts)
    const detailsByDiscount = discountWithQuantityArray.map(
      ({ discount, quantity }) => {
        return this.makeDetailByDiscount(discount, quantity, useCounts)
      },
    )
    const needRowDetailProductOptions = this.productOptions.filter(
      (productOption) =>
        productOption.type === 'kintoneIpAddressRestrictions' ||
        productOption.type === 'jibunPageSecurityOption',
    )
    const detailsByProductOptions = needRowDetailProductOptions.map(
      (kintoneIpAddressRestrictionsOption) =>
        this.makeDetailByProductOption(kintoneIpAddressRestrictionsOption),
    )
    const detailsByDiffCharge = diffCharges.map((diffCharge) =>
      this.makeDetailByDiffCharge(diffCharge),
    )

    return {
      rowDetails: [
        ...detailsByDiscount,
        ...detailsByProductOptions,
        ...detailsByDiffCharge,
      ],
      isOverUseCounts: this.isOverUseCounts(useCounts),
    }
  }

  public getPageDiffCharge(expectedUseCounts: number, useCounts: number) {
    if (this.product.type === 'monthly') {
      throw new FirebaseFunctionsError('internal', '想定外の利用です。')
    }
    if (expectedUseCounts > useCounts) {
      return 0
    }

    const { rowDetails: expectedCalculatedValues } = this.getRowDetailsInfo(
      expectedUseCounts,
      [],
    )
    const expectedCharge = expectedCalculatedValues.reduce(
      (preValue, currentValue) => preValue + currentValue.amount,
      0,
    )

    const { rowDetails: currentCalculatedValues } = this.getRowDetailsInfo(
      useCounts,
      [],
    )
    const currentCharge = currentCalculatedValues.reduce(
      (preValue, currentValue) => preValue + currentValue.amount,
      0,
    )

    // 月額の差額なので12で割っている
    const diffCharge = (currentCharge - expectedCharge) / 12
    if (diffCharge < 0) {
      return 0
    }

    return diffCharge
  }

  private isOverUseCounts(useCounts: number) {
    const matchDiscounts = this.discounts.filter(
      (discount) => discount.bottom <= useCounts,
    )

    const matchDiscountIndex = matchDiscounts.length - 1
    const matchDiscount = this.discounts[matchDiscountIndex]
    const isOverUseCounts = useCounts > matchDiscount.up

    return isOverUseCounts
  }
}
