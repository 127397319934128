import { Row, Col, Typography, Space } from 'antd'
import { FC } from 'react'
import { RowDetail } from '~/models/RowDetail'
import { EstimateDetailsTable } from '~/components/EstimateDetailsTable'
import { format } from 'date-fns'
import currencyFormatter from 'currency-formatter'
import sonic_garden from '../images/sonic_garden.png'
import { ProductType } from '~/models/Product'
import { Timestamp } from 'firebase/firestore'

const { Title, Text } = Typography

const TAX_RATE = 0.1

type Props = {
  monthCounts: number
  details: RowDetail[]
  type: ProductType
  expirationDate: Timestamp | null
  createdDate: Timestamp | null
  to: string
  estimateIndex: string
  name: string
}

export const EstimateView: FC<Props> = (props) => {
  const {
    monthCounts,
    details,
    type,
    createdDate,
    to,
    expirationDate,
    estimateIndex,
    name,
  } = props

  const isMonthly = type === 'monthly'

  const subtotalAmount = isMonthly
    ? details.reduce(
        (preValue, currentValue) => preValue + currentValue.amount,
        0,
      ) * monthCounts
    : details.reduce(
        (preValue, currentValue) => preValue + currentValue.amount,
        0,
      )
  const tax = subtotalAmount * TAX_RATE
  const totalAmount = subtotalAmount + tax

  return (
    <div className="max-w-screen-sm">
      <div className="flex justify-center">
        <Title level={3} className="w-fit">
          <u>御　見　積　書</u>
        </Title>
      </div>
      <div className="text-right">
        {createdDate && format(createdDate.toDate(), 'yyyy/MM/dd')}
      </div>
      <Row className="mb-5">
        <Col span={13}>
          <Space direction="vertical" className="w-full">
            <div className="border-b border-black flex justify-between">
              <Text>{to}</Text>
              <Text className="font-bold">御中</Text>
            </div>
            <Text>毎々格別のお引立てを賜わり有難うございます。</Text>
            <Text>ご照会の件、下記の通りお見積申し上げますので、</Text>
            <Text>何卒ご用命下さいますようお願い申し上げます。</Text>
          </Space>
          <div className="border-b border-black flex justify-between">
            <Text>番　　　　　号</Text>
            <Text>{estimateIndex}</Text>
          </div>
          <div className="border-b border-black flex justify-between">
            <Text>件　　　　　名</Text>
            <Text>{name}</Text>
          </div>
          <div className="border-b border-black flex justify-between">
            <Text>有効期限</Text>
            <Text>
              {expirationDate && format(expirationDate?.toDate(), 'yyyy/MM/dd')}
            </Text>
          </div>

          <div className="border-b border-black flex justify-between pt-10 text-lg">
            <Text>合計御見積金額</Text>
            <Text className="font-bold">
              {currencyFormatter.format(totalAmount, {
                code: 'JPY',
              })}
            </Text>
          </div>
        </Col>
        <Col span={2} />
        <Col span={9}>
          <Space direction="vertical">
            <Text className="text-xs">SonicGarden Inc.</Text>
            <Text className="text-lg font-bold">株式会社ソニックガーデン</Text>
            <Text className="text-xs">〒145-0071</Text>
            <Text className="text-xs">東京都大田区田園調布2-14-5</Text>
          </Space>
          <img src={sonic_garden} alt="" className="mx-auto h-24 my-2.5" />
        </Col>
      </Row>
      <Text>（御見積内訳）</Text>
      <EstimateDetailsTable
        monthCounts={monthCounts}
        details={details}
        type={type}
      />
    </div>
  )
}
