export const cProductOption = 'productOptions'

export type ProductOptionType =
  | 'kintoneIpAddressRestrictions'
  | 'teamPlan'
  | 'jibunPageSecurityOption'

export interface IProductOption {
  id?: string
  productId: string
  name: string
  descriptions: string[]
  value: number
  type: ProductOptionType
  isAgencyAvailable: boolean
}

export class ProductOption implements IProductOption {
  public readonly id: string
  public readonly productId: string
  public readonly name: string
  public readonly descriptions: string[]
  public readonly value: number
  public readonly type: ProductOptionType
  public readonly isAgencyAvailable: boolean
  constructor({
    id = '',
    productId = '',
    name = '',
    descriptions = [] as string[],
    value = 0,
    type = 'kintoneIpAddressRestrictions' as ProductOptionType,
    isAgencyAvailable = true,
  }) {
    this.id = id
    this.productId = productId
    this.name = name
    this.descriptions = descriptions
    this.value = value
    this.type = type
    this.isAgencyAvailable = isAgencyAvailable
  }

  public toObject(): IProductOption {
    return {
      id: this.id,
      productId: this.productId,
      name: this.name,
      descriptions: this.descriptions,
      value: this.value,
      type: this.type,
      isAgencyAvailable: this.isAgencyAvailable,
    }
  }
}
