import { Drawer, Tabs } from 'antd'
import { db } from '~/firebase-app'
import { query, collection, orderBy, where } from 'firebase/firestore'
import { cCustomer } from '~/models/Customer'
import { cCustomerProduct, CustomerProduct } from '~/models/CustomerProduct'
import { FC, useState } from 'react'
import { useToggle } from 'react-use'
import { CustomerProductView } from './CustomerProductView'
import { CustomerProductForm } from './forms/CustomerProductForm'
import useCollectionSubscription from './hooks/useCollectionSubscription'

const { TabPane } = Tabs

type Props = {
  customerId: string
}

export const CustomerProductsTabs: FC<Props> = (props) => {
  const { customerId } = props
  const defaultActiveKey = '0'
  const [activeTabKey, setActiveTabKey] = useState<string>(defaultActiveKey)
  const [isVisibleCustomerProductUpdateForm, toggleCustomerProductUpdateForm] =
    useToggle(false)

  const customerProductQuery = query(
    collection(db, cCustomer, customerId, cCustomerProduct),
    where('isAvailable', '==', true),
    orderBy('contractedDate', 'asc'),
  )

  const customerProducts = useCollectionSubscription(
    customerProductQuery,
    CustomerProduct,
  )

  const selectedCustomerProduct = (): CustomerProduct | undefined => {
    const findCustomerProduct = customerProducts[Number(activeTabKey)]
    return findCustomerProduct
  }
  return (
    <>
      <Tabs defaultActiveKey={defaultActiveKey} onChange={setActiveTabKey}>
        {customerProducts.map((customerProduct, index) => {
          const productName = customerProduct.product.name

          return (
            <TabPane tab={productName} key={index}>
              <CustomerProductView
                customerProduct={customerProduct}
                toggleCustomerProductUpdateForm={
                  toggleCustomerProductUpdateForm
                }
                customerId={customerId}
              />
            </TabPane>
          )
        })}
      </Tabs>
      <Drawer
        title="商品編集フォーム"
        width={720}
        onClose={toggleCustomerProductUpdateForm}
        visible={isVisibleCustomerProductUpdateForm}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <div className="py-16">
          <CustomerProductForm
            toggleCustomerProductForm={toggleCustomerProductUpdateForm}
            customerId={customerId}
            customerProduct={selectedCustomerProduct()}
            initialValues={{
              tenantId: selectedCustomerProduct()?.tenantId || '',
              expectedUserCounts: String(
                selectedCustomerProduct()?.expectedUserCounts,
              ),
            }}
          />
        </div>
      </Drawer>
    </>
  )
}
